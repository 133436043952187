import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { ConnetionProvider } from './context';
// import { BrowserRouter } from 'react-router-dom';

function getLibrary(provider) {
  return new Web3(provider);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <BrowserRouter>
    <Web3ReactProvider getLibrary={getLibrary}>
      <ConnetionProvider>
        <App />
      </ConnetionProvider>
    </Web3ReactProvider>
  // </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();