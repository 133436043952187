import React, { useEffect, useState } from 'react'

import ABI from "../abi/abi.json";
import axios from "axios";
import keccak256 from "keccak256";
import swap1 from "../img/logoicon.png";
import swap2 from "../img/meta.png";
import swap3 from "../img/Coinhub.png";
import swap4 from "../img/secure-pay.png";
import useConnection from "../context";

function BrisePay() {
    const {
        account,
        library,
        isActive,
        handleWalletModal,
        contractAddress
    } = useConnection();

    // const API_URL = "http://localhost:5006";
    const API_URL = "/api";
    const [loading, setLoading] = useState(false);
    const [orderInfo, setOrderInfo] = useState({});
    const [isValid, setisValid] = useState(false);

    const createTransaction = async () => {

        try {
            const auth = new URLSearchParams(document.location.search);
            const info = await axios.get(`${API_URL}/order${document.location.pathname}`, {
                auth: {
                    username: auth.get("key"),
                    password: auth.get("secret")
                }
            });
            const data = info.data.data
            if (data.status === "pending") {
                setisValid(true);
            } else {
                setisValid(false);
                setLoading(false);
                return;
            }
        } catch (err) {
            setisValid(false);
            setLoading(false);
            return;
        }
        try {
            setLoading(true);
            const auth = new URLSearchParams(document.location.search);
            const pow = Math.pow(10, 18);
            var amount = parseFloat(orderInfo?.amount) * pow;
            amount = amount.toLocaleString("fullwide", { useGrouping: false });

            const contract = new library.eth.Contract(ABI, contractAddress);
            const adminFee = await contract.methods.adminFee().call();

            await contract.methods
                .createTransaction(keccak256(decodeURIComponent(auth.get("secret"))))
                .send({ from: account, value: amount })
                .then(async (result) => {
                    const adminCut = (parseFloat(orderInfo?.amount) * adminFee) / 1000;
                    const vendorCut = parseFloat(orderInfo?.amount) - adminCut;
                    const data = {
                        "orderId": orderInfo.orderId,
                        "txHash": result.transactionHash,
                        "vendorCut": vendorCut,
                        "adminCut": adminCut,
                        "status": "success"
                    };
                    await axios.post(`${API_URL}/order/callback`, data, {
                        auth: {
                            username: auth.get("key"),
                            password: decodeURIComponent(auth.get("secret"))
                        }
                    });
                    window.location.replace(`${orderInfo.checkOutUrl}?txHash=${result.transactionHash}`)
                    setLoading(false);
                });
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const getOrderInfo = async () => {
        setLoading(true);
        try {
            const auth = new URLSearchParams(document.location.search);
            const info = await axios.get(`${API_URL}/order${document.location.pathname}`, {
                auth: {
                    username: auth.get("key"),
                    password: auth.get("secret")
                }
            });
            const data = info.data.data
            if (data.status === "pending") {
                setisValid(true);
            }
            setOrderInfo(data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getOrderInfo();
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])

    return (
        <div>
            <div className='pay-brise'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xl-12'>
                            <div className='brise-payble-section'>
                                <div className='row'>
                                    <div className='col-lg-7 col-md-7 col-sm-12 col-xl-7'>
                                        <div className='pay-details'>
                                            <p>Pay to</p>
                                            <h2>{orderInfo?.merchant?.displayName} - BRISE (BRC 20)</h2>
                                            <div className='ref-id'>
                                                Reference Id: <span>#{orderInfo?.orderId ? orderInfo?.orderId.substring(6) : ""}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-md-5 col-sm-12 col-xl-5'>
                                        <div className='pay-amt'>
                                            <div className='pay-title'>Payable Amount </div>
                                            <div className='pay'>BRISE {orderInfo?.amount}</div>
                                            {/* <div className='pay'>USD 30.09</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-xl-6'>
                            <div className='brise-chain'>
                                <h2>Use following wallet to connect<br /> <span>Bitgert</span> Chain</h2>
                                <ul>
                                    <li>
                                        <button type='button' className='brise-meta'><img src={swap2} alt="" />metamask wallet </button>
                                    </li>
                                    <li>
                                        <button type='button' className='brise-meta'><img src="/images/wallet-connect.png" alt="" />Wallet connect </button>
                                    </li>
                                    {/* <li>
                                        <button type='button' className='brise-meta'><img src={swap3} alt="" />Coinhub Wallet</button>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-xl-6'>
                            <div className='secure-payment'>
                                {isValid ?
                                    <>
                                        <h3>Secure Payment</h3>
                                        <div className='pay-box'>
                                            <p>Pay with</p>
                                            <div className='label-box'>
                                                <div className='label-first'>
                                                    <h2>{orderInfo?.amount}</h2>
                                                </div>
                                                <div className='label-second'>
                                                    <button type='button' className='pay-btn'><img src={swap1} alt="" />BRISE</button>
                                                </div>
                                            </div>
                                            {/* <div className='ruppes'>$30.09</div> */}
                                        </div>
                                        <div className='total-brise'>1 Brise = 0.0000007841 USD</div>
                                        <div className='wallte-connect'>
                                            {
                                                isActive ?
                                                    <button
                                                        type='button'
                                                        className='connet'
                                                        onClick={() => createTransaction()}
                                                        disabled={loading} >
                                                        {loading ? "Please Wait, Loading..." : "Pay Now"}
                                                    </button> :
                                                    <button
                                                        type='button'
                                                        className='connet'
                                                        onClick={() => {
                                                            handleWalletModal(true);
                                                        }}>
                                                        Connect Wallet
                                                    </button>
                                            }

                                        </div>
                                    </> :
                                    <>
                                        <h6 className='text-danger text-center mb-5 mt-5'>Payment Expired</h6>
                                        <h6 className='text-danger text-center mb-5 mt-5'>OR</h6>
                                        <h6 className='text-danger text-center mb-5 mt-5'>Already Paid</h6>
                                    </>
                                }

                                <div className='sec-icon-sym'>
                                    <div className='icon-text'><img src={swap4} alt="" />Secure Payment</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrisePay